import React from "react"
import { As } from "../../../../components/As/As"

type Props = {
  title: string
  as: string
}

export const Info: React.FC<Props> = props => {
  return (
    <>
      <As as={props.as} className="text-gray-800 font-bold text-sm uppercase">
        {props.title}
      </As>
      {props.children}
    </>
  )
}
