import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { PoemEntity } from "../../../entities/PoemEntity"
import { UserEntity } from "../../../entities/UserEntity"
import {
  Navigation,
  Footer,
  Hero,
  Breadcrumb,
} from "../../../components/Marketing"
import { Seo } from "../../../components/Seo/Seo"
import { Haikus } from "./components/Haikus"
import { Info } from "./components/Info"
import { PageProps } from "gatsby"

const Profile: React.FC<PageProps<
  null,
  {
    haikus: PoemEntity[]
    user: UserEntity
    profile: { biography: string | null }
  }
>> = props => {
  const { user, haikus, profile } = props.pageContext

  console.log(props.pageContext)

  const name = `${user.firstName} ${user.lastName}`

  const breadcrumbs = [
    { label: "Répertoire de haïkus", url: "/repertoire/" },
    {
      label: "Par auteurs",
      url: "/repertoire/auteurs/",
    },
    {
      label: name,
      url: "/repertoire/auteurs/" + user.id + "/",
    },
  ]

  const defaultBiography = `${name} n'a pas encore ajouté(e) de biographie. 
  
  Par contre l'auteur(e) a déjà conçu et publié(e) des haïkus, que vous pouvez retrouver ci-dessous.`

  return (
    <>
      <Seo
        title={`${name} | Ses haïkus et ses informations`}
        breadcrumbs={breadcrumbs}
        index={false}
      />

      <Navigation />
      <Hero
        theme={{
          as: "div",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          value: (
            <>
              Les haïkus de
              <br />
              <span className="text-green-600">{name}</span>
            </>
          ),
        }}
        description={{
          as: "div",
          value: (
            <>
              <Info title="Biographie" as="h4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: (profile?.biography || defaultBiography).replace(
                      /\n/gim,
                      "<br/>"
                    ),
                  }}
                />
              </Info>
            </>
          ),
        }}
      />

      <Haikus haikus={haikus} />

      <Footer />
    </>
  )
}

export default Profile
